define("labs-applicant-maps/utils/area-map-legend-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    projectArea: {
      label: 'Project Area',
      icon: {
        type: 'rectangle',
        layers: [{
          fill: 'rgba(0, 0, 0, 0)',
          stroke: 'rgba(0, 122, 122, 1)',
          'stroke-width': 1.25,
          'stroke-dasharray': '3.25,1.75'
        }]
      }
    },
    developmentSite: {
      label: 'Development Site',
      icon: {
        type: 'rectangle',
        layers: [{
          fill: 'rgba(0, 0, 0, 0)',
          stroke: 'rgba(237, 18, 18, 1)',
          'stroke-width': 1.25,
          'stroke-dasharray': '3,1.25,1.25,1.25'
        }]
      }
    },
    rezoningArea: {
      label: 'Rezoning Area',
      icon: {
        type: 'rectangle',
        layers: [{
          fill: 'rgba(0, 0, 0, 0)',
          stroke: 'rgba(0, 0, 0, 1)',
          'stroke-width': 2,
          'stroke-dasharray': '0.2,4',
          'stroke-linecap': 'round'
        }]
      }
    },
    projectGeometryBuffer: {
      label: '600 Foot Radius',
      icon: {
        type: 'rectangle',
        layers: [{
          fill: 'rgba(70, 130, 180, 0)',
          stroke: 'rgba(116, 4, 80, 1)',
          'stroke-width': 2,
          'stroke-dasharray': '2 2'
        }]
      }
    },
    landuseLegendItems: [{
      label: '1-2 Family Residential',
      icon: {
        type: 'fa-icon',
        layers: [{
          'fa-icon': 'square',
          color: '#FEFFA8'
        }]
      }
    }, {
      label: 'Multifamily Walkup',
      icon: {
        type: 'fa-icon',
        layers: [{
          'fa-icon': 'square',
          color: '#FCB842'
        }]
      }
    }, {
      label: 'Multifamily Elevator',
      icon: {
        type: 'fa-icon',
        layers: [{
          'fa-icon': 'square',
          color: '#B16E00'
        }]
      }
    }, {
      label: 'Mixed Commercial/Residential',
      icon: {
        type: 'fa-icon',
        layers: [{
          'fa-icon': 'square',
          color: '#ff8341'
        }]
      }
    }, {
      label: 'Commercial & Office',
      icon: {
        type: 'fa-icon',
        layers: [{
          'fa-icon': 'square',
          color: '#fc2929'
        }]
      }
    }, {
      label: 'Industrial & Mfg',
      icon: {
        type: 'fa-icon',
        layers: [{
          'fa-icon': 'square',
          color: '#E362FB'
        }]
      }
    }, {
      label: 'Transportation & Utility',
      icon: {
        type: 'fa-icon',
        layers: [{
          'fa-icon': 'square',
          color: '#E0BEEB'
        }]
      }
    }, {
      label: 'Public Facilities & Institiutions',
      icon: {
        type: 'fa-icon',
        layers: [{
          'fa-icon': 'square',
          color: '#44A3D5'
        }]
      }
    }, {
      label: 'Open Space & Outdoor Recreation ',
      icon: {
        type: 'fa-icon',
        layers: [{
          'fa-icon': 'square',
          color: '#78D271'
        }]
      }
    }, {
      label: 'Parking',
      icon: {
        type: 'fa-icon',
        layers: [{
          'fa-icon': 'square',
          color: '#BAB8B6'
        }]
      }
    }, {
      label: 'Vacant/No Data',
      icon: {
        type: 'fa-icon',
        layers: [{
          'fa-icon': 'square',
          color: '#555555'
        }]
      }
    }, {
      label: 'Other ',
      icon: {
        type: 'fa-icon',
        layers: [{
          'fa-icon': 'square',
          color: '#E7E7E7'
        }]
      }
    }],
    zoningLegendItems: [{
      label: 'Zoning District',
      icon: {
        type: 'rectangle',
        layers: [{
          fill: 'rgba(70, 130, 180, 0)',
          stroke: '#000',
          'stroke-width': 1.5
        }]
      }
    }, {
      label: 'C1-1',
      icon: {
        type: 'rectangle',
        layers: [{
          fill: 'rgba(70, 130, 180, 0)',
          stroke: '#000',
          'stroke-width': 1.5
        }]
      }
    }, {
      label: 'C1-2',
      icon: {
        type: 'rectangle',
        layers: [{
          fill: 'rgba(70, 130, 180, 0)',
          stroke: '#000',
          'stroke-width': 1.5
        }]
      }
    }, {
      label: 'C1-3',
      icon: {
        type: 'rectangle',
        layers: [{
          fill: 'rgba(70, 130, 180, 0)',
          stroke: '#000',
          'stroke-width': 1.5
        }]
      }
    }, {
      label: 'C1-4',
      icon: {
        type: 'rectangle',
        layers: [{
          fill: 'rgba(70, 130, 180, 0)',
          stroke: '#000',
          'stroke-width': 1.5
        }]
      }
    }, {
      label: 'C1-5',
      icon: {
        type: 'rectangle',
        layers: [{
          fill: 'rgba(70, 130, 180, 0)',
          stroke: '#000',
          'stroke-width': 1.5
        }]
      }
    }, {
      label: 'C2-1',
      icon: {
        type: 'rectangle',
        layers: [{
          fill: 'rgba(70, 130, 180, 0)',
          stroke: '#000',
          'stroke-width': 1.5
        }]
      }
    }, {
      label: 'C2-2',
      icon: {
        type: 'rectangle',
        layers: [{
          fill: 'rgba(70, 130, 180, 0)',
          stroke: '#000',
          'stroke-width': 1.5
        }]
      }
    }, {
      label: 'C2-3',
      icon: {
        type: 'rectangle',
        layers: [{
          fill: 'rgba(70, 130, 180, 0)',
          stroke: '#000',
          'stroke-width': 1.5
        }]
      }
    }, {
      label: 'C2-4',
      icon: {
        type: 'rectangle',
        layers: [{
          fill: 'rgba(70, 130, 180, 0)',
          stroke: '#000',
          'stroke-width': 1.5
        }]
      }
    }, {
      label: 'C2-5',
      icon: {
        type: 'rectangle',
        layers: [{
          fill: 'rgba(70, 130, 180, 0)',
          stroke: '#000',
          'stroke-width': 1.5
        }]
      }
    }]
  };
  _exports.default = _default;
});