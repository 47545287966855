define("labs-applicant-maps/router", ["exports", "labs-applicant-maps/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    metrics: Ember.inject.service(),
    didTransition: function didTransition() {
      this._super.apply(this, arguments);

      this._trackPage();
    },
    _trackPage: function _trackPage() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        var page = _this.url;

        var title = _this.getWithDefault('currentRouteName', 'unknown');

        _this.metrics.trackPage({
          page: page,
          title: title
        });
      });
    },
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    // eslint-disable-line
    this.route('projects', function () {
      this.route('show', {
        path: ':project_id'
      });
      this.route('new');
      this.route('edit', {
        path: ':project_id/edit'
      }, function () {
        this.route('steps', {
          path: '/'
        }, function () {
          this.route('development-site');
          this.route('project-area');
          this.route('rezoning');
          this.route('complete');
        });
        this.route('geometry-edit');
        this.route('map', function () {
          this.route('edit');
        });
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});