define("labs-applicant-maps/templates/components/project-geometries/modes/lots/toggle-mode-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U89E5uHw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[\"projects.edit.geometry-edit\",[27,\"query-params\",null,[[\"mode\"],[\"draw\"]]]],[[\"tagName\",\"class\",\"disabled\"],[\"button\",\"button gray expanded\",[27,\"not\",[[23,[\"isReadyToProceed\"]]],null]]],{\"statements\":[[0,\"  Switch to Manual Drawing\\n  \"],[7,\"small\"],[11,\"style\",\"display:block;line-height:1.75;\"],[9],[0,\"\\n    (edit the shape with draw tools)\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-applicant-maps/templates/components/project-geometries/modes/lots/toggle-mode-button.hbs"
    }
  });

  _exports.default = _default;
});