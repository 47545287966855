define("labs-applicant-maps/utils/compute-difference", ["exports", "@turf/difference"], function (_exports, _difference) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = computeDifference;

  var getDifferenceFeature = function getDifferenceFeature(featureA, featureB) {
    // get the difference
    var differenceFeature = (0, _difference.default)(featureA, featureB);
    if (differenceFeature) return differenceFeature;
    return null;
  };

  function computeDifference(current, proposed) {
    if (!proposed) {
      return {
        type: 'FeatureCollection',
        features: []
      };
    } // create an empty FeatureCollection to hold the difference sections


    var differenceFC = {
      type: 'FeatureCollection',
      features: []
    }; // flag differences

    proposed.features.forEach(function (feature) {
      var id = feature.id;
      var correspondingCurrentFeature = current.features.filter(function (d) {
        return d.id === id;
      })[0]; // if feature exists in currentZoning, compare the geometries

      if (correspondingCurrentFeature) {
        var differenceFeature = getDifferenceFeature(correspondingCurrentFeature, feature);
        if (differenceFeature) differenceFC.features.push(differenceFeature);
        var inverseDifferenceFeature = getDifferenceFeature(feature, correspondingCurrentFeature);
        if (inverseDifferenceFeature) differenceFC.features.push(inverseDifferenceFeature); // if the feature's label is different, push the whole feature as a difference fragment

        var label = feature.properties.label;
        var correspondingLabel = correspondingCurrentFeature.properties.label;
        var labelDifferent = label !== correspondingLabel;
        if (labelDifferent) differenceFC.features.push(feature);
      } else {
        differenceFC.features.push(feature);
      }
    });
    return differenceFC;
  }
});