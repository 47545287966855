define("labs-applicant-maps/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hhojqWWA",
    "block": "{\"symbols\":[\"banner\"],\"statements\":[[4,\"labs-ui/site-header\",null,[[\"responsiveNav\",\"betaNotice\"],[false,false]],{\"statements\":[[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"title\"]],\"expected `banner.title` to be a contextual component but found a string. Did you mean `(component banner.title)`? ('labs-applicant-maps/templates/application.hbs' @ L6:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[4,\"link-to\",[\"application\"],[[\"classNames\"],[\"site-title\"]],{\"statements\":[[0,\"Applicant Maps \"],[7,\"small\"],[11,\"class\",\"site-subtitle show-for-medium\"],[9],[10]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n\"],[7,\"div\"],[12,\"class\",[28,[\"site-main grid-x route-\",[21,\"currentRouteName\"]]]],[9],[0,\"\\n\"],[4,\"if\",[[27,\"user-agent\",[\"browser.isIE\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"cell large-6 large-offset-3 text-center\"],[11,\"style\",\"padding:2rem 0;\"],[9],[0,\"\\n      \"],[1,[27,\"fa-icon\",[\"exclamation-triangle\"],[[\"size\",\"class\"],[\"2x\",\"yellow-muted\"]]],false],[0,\"\\n      \"],[7,\"p\"],[9],[0,\"It looks like you're using Internet Explorer.  This site works best in a modern browser. Please try a current version of Edge, Chrome, or Firefox.\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[21,\"outlet\"],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[27,\"notification-container\",null,[[\"position\"],[\"bottom-right\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-applicant-maps/templates/application.hbs"
    }
  });

  _exports.default = _default;
});