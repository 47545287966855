define("labs-applicant-maps/templates/components/project-setup-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "12y/Yl1j",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"h1\"],[11,\"class\",\"header-large\"],[9],[0,\"\\n  \"],[1,[27,\"ember-inline-edit\",null,[[\"value\",\"onSave\",\"saveButtonClass\",\"cancelButtonClass\",\"showEditButton\",\"editButtonClass\"],[[27,\"readonly\",[[23,[\"project\",\"projectName\"]]],null],[27,\"action\",[[22,0,[]],\"save\",\"projectName\"],null],\"save-button button tiny\",\"button tiny\",true,\"edit-button button tiny no-margin clear\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"h6\"],[9],[0,\"Applicant:\"],[10],[0,\"\\n\"],[7,\"p\"],[11,\"class\",\"header-small\"],[9],[0,\"\\n  \"],[1,[27,\"ember-inline-edit\",null,[[\"value\",\"onSave\",\"saveButtonClass\",\"cancelButtonClass\",\"showEditButton\",\"editButtonClass\"],[[27,\"readonly\",[[23,[\"project\",\"applicantName\"]]],null],[27,\"action\",[[22,0,[]],\"save\",\"applicantName\"],null],\"save-button button tiny\",\"button tiny\",true,\"edit-button button tiny no-margin clear\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"h6\"],[9],[0,\"Project Number:\"],[10],[0,\"\\n\"],[7,\"p\"],[11,\"class\",\"header-small\"],[9],[0,\"\\n  \"],[1,[27,\"ember-inline-edit\",null,[[\"value\",\"onSave\",\"saveButtonClass\",\"cancelButtonClass\",\"showEditButton\",\"editButtonClass\"],[[27,\"readonly\",[[23,[\"project\",\"zapProjectId\"]]],null],[27,\"action\",[[22,0,[]],\"save\",\"zapProjectId\"],null],\"save-button button tiny\",\"button tiny\",true,\"edit-button button tiny no-margin clear\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"p\"],[9],[1,[21,\"share-button\"],false],[10],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-applicant-maps/templates/components/project-setup-status.hbs"
    }
  });

  _exports.default = _default;
});