define("labs-applicant-maps/templates/components/project-geometries/utils/canonical-geometry-reset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eAJ7X4gm",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"map-top-right\"]],{\"statements\":[[0,\"  \"],[7,\"button\"],[11,\"class\",\"reset-canonical-button button small gray\"],[9],[0,\"\\n    Reset \\n\"],[4,\"ember-tooltip\",null,[[\"side\",\"tooltipClassName\",\"popperContainer\"],[\"right\",\"ember-tooltip draw-tooltip\",\"body\"]],{\"statements\":[[0,\"      \"],[7,\"p\"],[11,\"class\",\"no-margin\"],[9],[0,\"Undo all your changes and revert back to the original zoning boundaries.\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[3,\"action\",[[22,0,[]],\"resetCanonical\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-applicant-maps/templates/components/project-geometries/utils/canonical-geometry-reset.hbs"
    }
  });

  _exports.default = _default;
});