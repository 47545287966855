define("labs-applicant-maps/utils/wizard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = wizard;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  function wizard(steps, project) {
    return steps.find(function (_ref, index, array) {
      var conditions = _ref.conditions;
      // Return this step if it's the last.
      // This means all other steps' conditions were met, indicating
      // all other steps are complete
      if (index === array.length - 1) return true;
      var attributeKeys = Object.keys(conditions);
      var currentAttributes = project.getProperties.apply(project, _toConsumableArray(attributeKeys)); // return this step if ANY attributes have unmet conditions

      return attributeKeys.any(function (attr) {
        var currentCondition = currentAttributes[attr]; // make project conditionalChecks's 'this'

        var conditionalCheck = conditions[attr].bind(project, currentCondition, attr);
        var generatedValue = !conditionalCheck();
        return generatedValue;
      });
    });
  }
});