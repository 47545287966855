define("labs-applicant-maps/utils/elevate-geojson-ids", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  // takes `id` from properties, and copies it to `id` at the top level of each feature
  // used by mapbox-gl-draw to identify features for when editing
  function _default(FeatureCollection) {
    // add an id to the top level of each feature object, for use by mapbox-gl-draw
    var features = FeatureCollection.features;
    FeatureCollection.features = features.map(function (feature) {
      feature.id = feature.properties.id;
      return feature;
    });
    return FeatureCollection;
  }
});