define("labs-applicant-maps/components/labs-ui/icons/line", ["exports", "labs-ui/components/labs-ui/icons/line"], function (_exports, _line) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _line.default;
    }
  });
});