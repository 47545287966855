define("labs-applicant-maps/utils/project-geom-icons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var developmentSiteIcon = {
    type: 'rectangle',
    layers: [{
      fill: 'rgba(0, 0, 0, 0)',
      stroke: 'rgba(237, 18, 18, 1)',
      'stroke-width': 1.25,
      'stroke-dasharray': '3,1.25,1.25,1.25'
    }]
  };
  var projectAreaIcon = {
    type: 'rectangle',
    layers: [{
      fill: 'rgba(0, 0, 0, 0)',
      stroke: 'rgba(0, 122, 122, 1)',
      'stroke-width': 1,
      'stroke-dasharray': '3.25,1.75'
    }]
  };
  var underlyingZoningIcon = {
    type: 'rectangle',
    layers: [{
      fill: 'rgba(0, 0, 0, 0)',
      stroke: 'rgba(0, 0, 0, 0.7)',
      'stroke-width': 1
    }]
  };
  var commercialOverlaysIcon = {
    type: 'rectangle',
    layers: [{
      fill: 'rgba(0, 0, 0, 0)',
      stroke: 'rgba(0, 0, 0, 0.7)',
      'stroke-width': 1
    }]
  };
  var specialPurposeDistrictsIcon = {
    type: 'rectangle',
    layers: [{
      fill: 'rgba(94, 102, 51, 0.2)',
      stroke: 'rgba(0, 0, 0, 0)',
      'stroke-width': 0
    }]
  };
  var rezoningAreaIcon = {
    type: 'rectangle',
    layers: [{
      fill: 'rgba(0, 0, 0, 0)',
      stroke: 'rgba(0, 0, 0, 1)',
      'stroke-width': 2,
      'stroke-dasharray': '0.2,4',
      'stroke-linecap': 'round'
    }]
  };
  var _default = {
    developmentSiteIcon: developmentSiteIcon,
    projectAreaIcon: projectAreaIcon,
    underlyingZoningIcon: underlyingZoningIcon,
    commercialOverlaysIcon: commercialOverlaysIcon,
    specialPurposeDistrictsIcon: specialPurposeDistrictsIcon,
    rezoningAreaIcon: rezoningAreaIcon
  };
  _exports.default = _default;
});