define("labs-applicant-maps/templates/components/map-form/inset-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N/CGJJG+",
    "block": "{\"symbols\":[\"map\",\"source\"],\"statements\":[[4,\"mapbox-gl\",null,[[\"id\",\"initOptions\",\"mapLoaded\"],[\"inset-map\",[27,\"hash\",null,[[\"zoom\",\"center\",\"interactive\",\"pitchWithRotate\",\"preserveDrawingBuffer\"],[8,[27,\"array\",[-73.96532400540775,40.709710016659386],null],false,false,true]]],[27,\"action\",[[22,0,[]],\"handleMapLoad\"],null]]],{\"statements\":[[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"source\"]],\"expected `map.source` to be a contextual component but found a string. Did you mean `(component map.source)`? ('labs-applicant-maps/templates/components/map-form/inset-map.hbs' @ L12:C5) \"],null]],[[\"options\"],[[27,\"mapbox-geojson-source\",[[23,[\"boundsPolygon\"]]],null]]],{\"statements\":[[0,\"    \"],[1,[22,2,[\"layer\"]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-applicant-maps/templates/components/map-form/inset-map.hbs"
    }
  });

  _exports.default = _default;
});