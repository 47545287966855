define("labs-applicant-maps/helpers/find-relative-step", ["exports", "labs-applicant-maps/models/project"], function (_exports, _project) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.findRelativeStep = findRelativeStep;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  // takes a "routing" simple object (requires `route` key),
  // and looks up first occurring step (defined in model)
  // with that routing information
  // a "reverse lookup" of step from routing information
  // alloways an optional offset
  function findRelativeStep(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        routing = _ref2[0],
        _ref2$ = _ref2[1],
        offset = _ref2$ === void 0 ? 0 : _ref2$;

    var route = routing.route,
        type = routing.type;

    var foundIndex = _project.projectProcedure.findIndex(function (_ref3) {
      var currRouting = _ref3.routing;
      return currRouting.route === route && currRouting.type === type;
    });

    return _project.projectProcedure[foundIndex + offset];
  }

  var _default = Ember.Helper.helper(findRelativeStep);

  _exports.default = _default;
});