define("labs-applicant-maps/templates/projects/edit/geometry-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ypZu6wFB",
    "block": "{\"symbols\":[],\"statements\":[[4,\"project-geometries\",null,[[\"model\",\"layerGroups\",\"type\",\"mode\"],[[23,[\"model\",\"project\"]],[23,[\"layerGroups\"]],[23,[\"type\"]],[23,[\"mode\"]]]],{\"statements\":[[0,\"  \"],[1,[27,\"project-geometries/map-legend\",null,[[\"model\",\"type\"],[[23,[\"model\",\"project\"]],[23,[\"type\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[21,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-applicant-maps/templates/projects/edit/geometry-edit.hbs"
    }
  });

  _exports.default = _default;
});