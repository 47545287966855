define("labs-applicant-maps/templates/components/back-to-dashboard-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VOOv/la1",
    "block": "{\"symbols\":[\"previous\"],\"statements\":[[4,\"if\",[[27,\"and\",[[27,\"not-eq\",[[23,[\"model\",\"project\",\"currentStep\",\"step\"]],\"complete\"],null],[27,\"not-eq\",[[23,[\"model\",\"project\",\"currentStep\",\"step\"]],\"dashboard\"],null]],null]],null,{\"statements\":[[4,\"let\",[[27,\"find-relative-step\",[[27,\"hash\",null,[[\"route\",\"type\",\"mode\"],[[23,[\"router\",\"currentRouteName\"]],[23,[\"type\"]],[23,[\"mode\"]]]]],-1],null]],null,{\"statements\":[[4,\"link-to\",[[22,1,[\"routing\",\"route\"]],[27,\"query-params\",null,[[\"mode\",\"type\"],[[22,1,[\"routing\",\"mode\"]],[22,1,[\"routing\",\"type\"]]]]]],[[\"class\"],[\"button gray\"]],{\"statements\":[[0,\"      \"],[1,[27,\"fa-icon\",[\"arrow-left\"],null],false],[0,\" Back\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[4,\"link-to\",[\"projects.show\",[23,[\"model\",\"project\",\"id\"]]],[[\"class\"],[\"button gray\"]],{\"statements\":[[0,\"    Cancel\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-applicant-maps/templates/components/back-to-dashboard-button.hbs"
    }
  });

  _exports.default = _default;
});