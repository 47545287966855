define("labs-applicant-maps/components/zf-dropdown-menu", ["exports", "ember-cli-foundation-6-sass/components/zf-dropdown-menu"], function (_exports, _zfDropdownMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _zfDropdownMenu.default;
    }
  });
});