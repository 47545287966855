define("labs-applicant-maps/services/user-agent", ["exports", "ember-useragent/services/user-agent"], function (_exports, _userAgent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _userAgent.default;
    }
  });
});