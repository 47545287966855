define("labs-applicant-maps/utils/is-feature-collection-changed", ["exports", "@turf/boolean-equal"], function (_exports, _booleanEqual) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isFeatureCollectionChanged;

  function isFeatureCollectionChanged(initial, proposed) {
    var initialGeoms = Ember.get(initial, 'features');
    var proposedGeoms = Ember.get(proposed, 'features'); // sort according to property id. difference judgment is order-sensitive
    // because it is compare features by index

    [initialGeoms, proposedGeoms].forEach(function (features) {
      features.sort(function (feature1, feature2) {
        var id = feature2.properties.id;
        var compareId = feature1.properties.id;
        return id - compareId;
      });
    });
    return function () {
      // console.log('if the lengths differ, return true');
      if (initialGeoms.length !== proposedGeoms.length) return true; // console.log('check for null geoms');
      // null geoms are considered invalid and so a comparison can't be made

      if (initialGeoms.every(function (_ref) {
        var geometry = _ref.geometry;
        return geometry === null;
      }) && proposedGeoms.every(function (_ref2) {
        var geometry = _ref2.geometry;
        return geometry === null;
      })) {
        return false;
      } // console.log('check if any are unequal');


      return initialGeoms.any(function (feature, index) {
        return !(0, _booleanEqual.default)(feature, proposedGeoms[index]);
      });
    }();
  }
});