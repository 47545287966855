define("labs-applicant-maps/utils/mapbox-gl-draw/annotations/generate-curvature", ["exports", "@turf/length", "@turf/bearing", "@turf/destination", "@turf/along", "@turf/distance"], function (_exports, _length, _bearing, _destination, _along, _distance) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var deepCopy = function deepCopy(object) {
    return JSON.parse(JSON.stringify(object));
  }; // this function takes a geojson LineString Feature
  // and returns the mapboxGL layers necessary to display the various components
  // the layers all carry their own sources, so no need to add sources separately beforehand


  var getArrowLayers = function getArrowLayers(lineFeature, id, annotationType) {
    // calculate bearing
    var coordinates = lineFeature.geometry.coordinates;
    var lineBearing = (0, _bearing.default)(coordinates[0], coordinates[1]); // set common layout object

    var layout = {
      'icon-image': 'arrow',
      'icon-size': 0.04,
      'icon-rotate': {
        type: 'identity',
        property: 'rotation'
      },
      'icon-anchor': 'top',
      'icon-rotation-alignment': 'map',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true
    };
    var startArrowLayer = {
      type: 'symbol',
      source: {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: lineFeature.geometry.coordinates[0]
          },
          properties: {
            rotation: lineBearing + 180
          }
        }
      },
      layout: layout
    };
    var endArrowLayer = {
      type: 'symbol',
      source: {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: lineFeature.geometry.coordinates[lineFeature.geometry.coordinates.length - 1]
          },
          properties: {
            rotation: annotationType === 'linear' ? lineBearing : lineBearing + 50
          }
        }
      },
      layout: layout
    };
    return [startArrowLayer, endArrowLayer];
  };

  var getCurve = function getCurve(lineFeature) {
    // takes a GeoJson LineString Feature with two vertices, interpolates an arc between them
    // returns a new GeoJson LineString for the arc
    var coordinates = lineFeature.geometry.coordinates;
    var lineBearing = (0, _bearing.default)(coordinates[0], coordinates[1]); // get bearing of original line

    var lineLength = (0, _length.default)(lineFeature); // get length of original line

    var factor = 2; // factor determines the concavity of the arc

    var chunks = 20; // number of segments the new arc LineString should have

    var chunkLength = lineLength / chunks; // the geographic length of each chunk
    // here we interpolate points along the original line, and then offset each at a 90 degree angle off the original line
    // the distance to offset depends on distance from the center (the center-most point will be the apex of the arc)
    //

    var center = (0, _along.default)(lineFeature, lineLength / 2).geometry.coordinates; // get coordinates for the center of the original line

    var newCoordinates = []; // empty array to push the offset coordinates to

    for (var i = 1; i < chunks; i += 1) {
      // calculate the position of a new vertex along the original line
      var originalCoordinate = (0, _along.default)(lineFeature, i * chunkLength).geometry.coordinates; // get the geographic distance from the center, this will be used to determine the offset

      var distanceFromCenter = (0, _distance.default)(originalCoordinate, center); // calculate the offset distance

      var offsetDistance = distanceFromCenter * distanceFromCenter / (lineLength * (-1 * factor)) + 0.25 * lineLength / factor; // calculate the bearing

      var offsetBearing = lineBearing - 90; // caclulate the offset vertex location

      var newCoordinate = (0, _destination.default)(originalCoordinate, offsetDistance, offsetBearing).geometry.coordinates;
      newCoordinates.push(newCoordinate);
    } // push all of the new coordinates into the original two-vertex line


    newCoordinates.forEach(function (coordinate, i) {
      lineFeature.geometry.coordinates.splice(i + 1, 0, coordinate);
    });
    return lineFeature;
  }; // centerline layer


  var getCenterlineLayers = function getCenterlineLayers(lineFeature) {
    // calculate bearing
    var coordinates = lineFeature.geometry.coordinates;
    var lineBearing = (0, _bearing.default)(coordinates[0], coordinates[1]);
    var c = null;
    var cAngle = null; // we use icon-translate (x and y) to offset the icon from the end of the line based on the lineBearing, calculations below
    // set all lineBearing angles to be between 0 and 90
    // this way we can measure how far the line is angled from the vertical axes

    if (lineBearing > 0 && lineBearing < 90) {
      // quadrant I
      cAngle = lineBearing;
    } else if (lineBearing > -90 && lineBearing < 0) {
      // quadrant II
      cAngle = -1 * lineBearing;
    } else if (lineBearing < -90) {
      // quadrant III
      cAngle = lineBearing + 180;
    } else if (lineBearing > 90 && lineBearing < 180) {
      // quadrant IV
      cAngle = 180 - lineBearing;
    } // c is the length of the offset, it represents c^2 in the pythagorean theorem
    // because our centerline icon is taller than it is wide, we set c to be different lengths depending on the angle from the vertical axis
    // we make it so that lines that are closer to vertical have a greater c length
    // this way we can make it appear as if the icon is equally far from the end of each line regardless of lineBearing


    if (cAngle < 30) {
      c = 11;
    } else if (cAngle > 30 && cAngle < 60) {
      c = 10;
    } else if (cAngle > 60) {
      c = 9;
    } // we need to convert the lineBearing to radians in order to get its tangent


    var radiansBearing = lineBearing * Math.PI / 180; // These equations are based off of the pythagorean theorem: (a^2 + b^2 = c^2)
    // and the equation of the tangent: tan(bearing angle) = opposite/adjacent OR tan(bearing angle) = x/y
    // using substitution, y and x were isolated

    var y = null;
    var x = null;
    y = Math.sqrt(Math.pow(c, 2) / (Math.pow(Math.tan(radiansBearing), 2) + 1));
    x = Math.sqrt(Math.pow(c, 2) - Math.pow(y, 2)); // the icon-translate property is "Distance that the icon's anchor is moved from its original placement"
    // although it is the icon that actually moves, not the line, when "offsetting" the icon we have to set it up as if we are moving the anchor NOT the icon
    // this means that if we are offsetting an icon to the RIGHT of the line, we would usually think of it as having a positive x value for icon-translate, but instead we are
    // actually moving the anchor to the left, so the x value would be negative (same logic for the y value)

    if (lineBearing > 0 && lineBearing < 90) {
      // quadrant I
      x = -x;
    } else if (lineBearing < -90) {
      // quadrant II
      y = -y;
    } else if (lineBearing > 90 && lineBearing < 180) {
      // quadrant IV
      y = -y;
      x = -x;
    } // set layout for centerline


    var layoutCenterline = {
      'icon-image': 'centerline',
      'icon-size': 0.01,
      'icon-keep-upright': true,
      'icon-rotation-alignment': 'map',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true
    }; // set layout for end arrow

    var layoutArrow = {
      'icon-image': 'arrow',
      'icon-size': 0.04,
      'icon-rotate': {
        type: 'identity',
        property: 'rotation'
      },
      'icon-anchor': 'top',
      'icon-rotation-alignment': 'map',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true
    };
    var centerlineLayer = {
      type: 'symbol',
      source: {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: lineFeature.geometry.coordinates[0]
          }
        }
      },
      layout: layoutCenterline,
      paint: {
        'icon-translate': [x, y]
      }
    };
    var endArrowLayerForCenterline = {
      type: 'symbol',
      source: {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: lineFeature.geometry.coordinates[lineFeature.geometry.coordinates.length - 1]
          },
          properties: {
            rotation: lineBearing
          }
        }
      },
      layout: layoutArrow
    };
    return [centerlineLayer, endArrowLayerForCenterline];
  };

  var buildSquareLayers = function buildSquareLayers(lineFeature) {
    // takes a GeoJson LineString Feature with two vertices
    // returns mapboxGL layers to show a right angle symbol
    var coordinates = lineFeature.geometry.coordinates;
    var lineBearing = (0, _bearing.default)(coordinates[0], coordinates[1]);
    var lineLength = (0, _length.default)(lineFeature); // get length of original line

    var segmentLength = lineLength * 0.667;
    var midPoint = (0, _along.default)(lineFeature, segmentLength).geometry.coordinates; // get coordinates for the center of the original line
    // pythagorean theorum!

    var wingDistance = segmentLength * Math.sqrt(2) / 2; // start with the center point of the line, and project away at a bearing + 135 degrees

    var rightWingVertex = (0, _destination.default)(midPoint, wingDistance, lineBearing + 135).geometry.coordinates;
    var leftWingVertex = (0, _destination.default)(midPoint, wingDistance, lineBearing - 135).geometry.coordinates;
    var squareLayer = {
      type: 'line',
      source: {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: [leftWingVertex, midPoint, rightWingVertex]
          }
        }
      }
    };
    return [squareLayer];
  };

  var buildLineLayers = function buildLineLayers(rawLineFeature, annotationType) {
    var lineFeature = deepCopy(rawLineFeature); // takes a GeoJson LineString Feature with two vertices, and annotationType ('linear' or 'curved')
    // TODO validate the linefeature to make sure it has only two vertices,
    // and has a label property

    var id = lineFeature.id; // generate the line layer

    var lineLayer = {
      type: 'line',
      source: {
        type: 'geojson',
        data: annotationType === 'linear' ? lineFeature : getCurve(lineFeature)
      }
    }; // generate the label layer

    var labelLayer = {
      type: 'symbol',
      source: {
        type: 'geojson',
        data: lineFeature
      },
      layout: {
        'text-field': '{label}',
        'symbol-placement': 'line-center',
        'text-offset': [0, 0],
        'text-justify': 'center',
        'text-anchor': 'center',
        'text-size': 13,
        'text-allow-overlap': true,
        'text-ignore-placement': true
      },
      paint: {
        'text-color': 'rgba(0, 0, 0, 1)',
        'text-halo-color': 'rgba(255, 255, 255, 1)',
        'text-halo-width': 3,
        'text-halo-blur': 1
      }
    }; // generate the arrow symbol layers

    var arrowLayers = getArrowLayers(lineFeature, id, annotationType); // return an array of all of the layers

    return [lineLayer, labelLayer].concat(_toConsumableArray(arrowLayers));
  };

  var buildcenterLineLayers = function buildcenterLineLayers(rawLineFeature) {
    var lineFeature = deepCopy(rawLineFeature); // TODO validate the linefeature to make sure it has only two vertices,

    var id = lineFeature.id; // generate the line layer

    var lineLayer = {
      type: 'line',
      source: {
        type: 'geojson',
        data: lineFeature
      }
    }; // generate the centerline symbol layers

    var centerlineLayers = getCenterlineLayers(lineFeature, id); // return an array of all of the layers

    return [lineLayer].concat(_toConsumableArray(centerlineLayers));
  };

  function _default() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var feature = args[0],
        type = args[1];

    if (type === 'curved' || type === 'linear') {
      return buildLineLayers.apply(void 0, args);
    }

    if (type === 'square') {
      return buildSquareLayers.apply(void 0, args);
    }

    if (type === 'label') {
      return [{
        type: 'symbol',
        source: {
          type: 'geojson',
          data: feature
        },
        layout: {
          'text-field': '{label}',
          'symbol-placement': 'point',
          'text-offset': [0, -1],
          'text-justify': 'center',
          'text-anchor': 'center',
          'text-size': ['match', ['get', 'textSize'], 'large', 16, 12],
          'text-font': ['match', ['get', 'textFont'], 'bold', // condition
          ['literal', ['Open Sans Bold', 'Arial Unicode MS Bold']], // match
          ['literal', ['Open Sans Regular', 'Arial Unicode MS Regular']]],
          'text-allow-overlap': true,
          'text-ignore-placement': true
        }
      }];
    }

    if (type === 'centerline') {
      return buildcenterLineLayers.apply(void 0, args);
    }

    return [];
  }
});