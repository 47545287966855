define("labs-applicant-maps/templates/components/project-geometries/utils/annotations-renderer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F+b2JV8v",
    "block": "{\"symbols\":[\"layer\"],\"statements\":[[4,\"each\",[[23,[\"displayLayers\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[23,[\"map\",\"layer\"]],\"expected `map.layer` to be a contextual component but found a string. Did you mean `(component map.layer)`? ('labs-applicant-maps/templates/components/project-geometries/utils/annotations-renderer.hbs' @ L2:C4) \"],null]],[[\"layer\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-applicant-maps/templates/components/project-geometries/utils/annotations-renderer.hbs"
    }
  });

  _exports.default = _default;
});