define("labs-applicant-maps/templates/components/search-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zshAPGzJ",
    "block": "{\"symbols\":[\"source\",\"search\",\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"search-and-bbl-lookup-container\"],[9],[0,\"\\n\"],[4,\"labs-search\",null,[[\"searchPlaceholder\",\"onSelect\",\"typeTitleLookup\",\"onClear\"],[\"Search for Address...\",[27,\"action\",[[22,0,[]],\"selectSearchResult\"],null],[27,\"hash\",null,[[\"lot\"],[\"Addresses\"]]],[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"geocodedFeature\"]],null],null]],null]]],{\"statements\":[[0,\"    \"],[7,\"small\"],[11,\"class\",\"dark-gray\"],[9],[1,[22,2,[\"result\",\"label\"]],false],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n  \"],[14,3,[[27,\"hash\",null,[[\"flyTo\"],[[27,\"action\",[[22,0,[]],\"flyTo\"],null]]]]]],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"geocodedFeature\"]]],null,{\"statements\":[[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[23,[\"map\",\"source\"]],\"expected `map.source` to be a contextual component but found a string. Did you mean `(component map.source)`? ('labs-applicant-maps/templates/components/search-handler.hbs' @ L16:C5) \"],null]],[[\"options\"],[[23,[\"geocodedFeature\"]]]],{\"statements\":[[0,\"    \"],[1,[27,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"layer\"]],\"expected `source.layer` to be a contextual component but found a string. Did you mean `(component source.layer)`? ('labs-applicant-maps/templates/components/search-handler.hbs' @ L17:C6) \"],null]],[[\"layer\"],[[23,[\"geocodedLayer\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-applicant-maps/templates/components/search-handler.hbs"
    }
  });

  _exports.default = _default;
});