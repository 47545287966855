define("labs-applicant-maps/utils/is-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isEmpty;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  // an empty FeatureCollection should be considered falsey
  function isEmpty(property) {
    var isNativelyFalsey = !!property === false;
    var isHash = _typeof(property) === 'object' && property !== null;

    if (isHash) {
      var isFeatureCollection = Object.keys(property).includes('type') && Object.keys(property).includes('features');

      if (isFeatureCollection) {
        // check that every geometry is falsey
        return property.features.map(function (_ref) {
          var geometry = _ref.geometry;
          return geometry;
        }).every(function (geom) {
          return !!geom === false;
        });
      }
    }

    return isNativelyFalsey;
  }
});