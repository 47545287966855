define("labs-applicant-maps/utils/unify-polygons", ["exports", "@turf/combine"], function (_exports, _combine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = unifyPolygons;

  // arg: FeatureCollection
  // it runs a turf combination and fines all multipolygons
  function unifyPolygons(featureCollection) {
    var _turfCombine$features = (0, _combine.default)(featureCollection).features.find(function (_ref) {
      var type = _ref.geometry.type;
      return type === 'MultiPolygon';
    }),
        geometry = _turfCombine$features.geometry;

    return geometry;
  }
});