define("labs-applicant-maps/templates/components/project-geometries/types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xtFBh9zy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"\\n\"],[1,[27,\"project-geometry-renderer\",null,[[\"map\",\"model\",\"type\"],[[23,[\"map\"]],[23,[\"model\"]],[23,[\"type\"]]]]],false],[0,\"\\n\\n\"],[0,\"\\n\"],[4,\"component\",[[23,[\"componentForType\"]]],[[\"model\",\"mode\",\"map\",\"isReadyToProceed\",\"save\",\"modeComponent\"],[[23,[\"geometricPropertyForType\"]],[23,[\"mode\"]],[23,[\"map\"]],[23,[\"geometricPropertyForType\",\"isReadyToProceed\"]],[27,\"action\",[[22,0,[]],\"save\"],null],[27,\"component\",[[23,[\"componentForMode\"]]],[[\"map\",\"geometricProperty\"],[[23,[\"map\"]],[23,[\"geometricPropertyForMode\"]]]]]]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"cell shrink\"],[11,\"style\",\"padding-right:1rem;\"],[9],[0,\"\\n    \"],[1,[27,\"back-to-dashboard-button\",null,[[\"model\",\"type\",\"mode\"],[[23,[\"geometricPropertyForType\"]],[23,[\"type\"]],[23,[\"mode\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-applicant-maps/templates/components/project-geometries/types.hbs"
    }
  });

  _exports.default = _default;
});