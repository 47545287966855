define("labs-applicant-maps/templates/components/mapbox-gl-draw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+LayNxCJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[23,[\"map\"]]]],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"draw-controls\"],[11,\"id\",\"draw-controls\"],[9],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"map-top-right\"],[11,\"id\",\"map-top-right\"],[9],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-applicant-maps/templates/components/mapbox-gl-draw.hbs"
    }
  });

  _exports.default = _default;
});